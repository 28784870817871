import React from 'react';
import styled, { css } from 'styled-components';
import { theme } from '../../theme';

const StyledHeading = styled.h3`
  font-size: 1.5rem;
  font-weight: 800;
  color: ${({ color }) => theme.colors[color]};
  margin-bottom: var(--spacing-xsmall);

  @media (min-width: ${() => theme.breakPoints.large}) {
    font-size: inherit;
    margin-bottom: var(--spacing-small);
  }
`;

export default function Heading({ text, color, id }) {
  return (
    <StyledHeading id={id} color={color}>
      {text}
    </StyledHeading>
  );
}
