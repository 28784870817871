import React from "react";
import styled from "styled-components";
import { graphql, Link } from "gatsby";
import { theme } from "../../theme";
import ContentWrapper from "../components/ContentWrapper";
import Heading from "../components/Heading";
import Section from "../components/Section";
import SEO from "../components/SEO";
import Spacer from "../components/Spacer";
import Banner from "../containers/WebApp/Blog/Banner";

const PostContainer = styled.div`
  display: grid;
  grid-gap: var(--spacing-medium);
  margin-bottom: var(--spacing-large);

  @media (min-width: ${() => theme.breakPoints.small}) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: ${() => theme.breakPoints.large}) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (min-width: ${() => theme.breakPoints.xlarge}) {
    grid-template-columns: repeat(4, 1fr);
  }
`;

const PostWrapper = styled.div`
  width: 100%;
  background: ${theme.colors.primaryLight};
  color: ${theme.colors.primaryDark};
  border-radius: 5px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);

  img {
    border-radius: 5px 5px 0 0;
  }
`;

const ImgWrapper = styled.div`
  background: ${theme.colors.primaryLight};
  aspect-ratio: 16 / 9;

  img {
    object-fit: cover;
    height: 100%;
    width: 100%;
    vertical-align: middle;
  }
`;

const TitleWrapper = styled.div`
  padding: var(--spacing-small);

  p {
    margin-bottom: calc(var(--spacing-small) / 4);
  }
`;

const Title = styled.h2`
  font-weight: 800;
`;

const Post = ({ title, date, thumbnail, slug }) => (
  <Link to={`/${slug}`}>
    <PostWrapper>
      <ImgWrapper>
        <img src={`/${thumbnail}`} alt={title} />
      </ImgWrapper>
      <TitleWrapper>
        <p>{date}</p>
        <Title>{title}</Title>
      </TitleWrapper>
    </PostWrapper>
  </Link>
);

export default function BlogPage({ data }) {
  const posts = data.posts.nodes.filter(
    (post) => post.frontmatter.title !== "Dummy Post"
  );

  return (
    <>
      <SEO title="Blog" />
      <Banner />
      <Section>
        <Spacer size="xlarge" />
        <ContentWrapper>
          <Heading text="Blog" />
          <PostContainer>
            {posts.map((post) => (
              <Post
                title={post.frontmatter.title}
                date={post.frontmatter.date}
                thumbnail={post.frontmatter.thumbnail}
                slug={post.frontmatter.slug}
                key={post.id}
              />
            ))}
          </PostContainer>
        </ContentWrapper>
      </Section>
    </>
  );
}

export const pageQuery = graphql`
  query {
    posts: allMarkdownRemark {
      nodes {
        id
        frontmatter {
          thumbnail
          date
          slug
          title
        }
      }
    }
  }
`;
